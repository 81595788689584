<wordle>

    <div class="pure-g char-container">
        <div class="pure-u-1-5">
            <char each={ letter in props.letters } if={ letter.is_valid(0) } letter={ letter.char } type="valid"/>
        </div>
        <div class="pure-u-1-5">
            <char each={ letter in props.letters } if={ letter.is_valid(1) } letter={ letter.char } type="valid"/>
        </div>
        <div class="pure-u-1-5">
            <char each={ letter in props.letters } if={ letter.is_valid(2) } letter={ letter.char } type="valid"/>
        </div>
        <div class="pure-u-1-5">
            <char each={ letter in props.letters } if={ letter.is_valid(3) } letter={ letter.char } type="valid"/>
        </div>
        <div class="pure-u-1-5">
            <char each={ letter in props.letters } if={ letter.is_valid(4) } letter={ letter.char } type="valid"/>
        </div>
    </div>
    <div each={ letter in props.letters } if={ letter.is_present() } class="pure-g char-container">
        <div class="pure-u-1-5">
            <char if={ letter.is_present(0) } letter={ letter.char } type="present"/>
        </div>
        <div class="pure-u-1-5">
            <char if={ letter.is_present(1) } letter={ letter.char } type="present"/>
        </div>
        <div class="pure-u-1-5">
            <char if={ letter.is_present(2) } letter={ letter.char } type="present"/>
        </div>
        <div class="pure-u-1-5">
            <char if={ letter.is_present(3) } letter={ letter.char } type="present"/>
        </div>
        <div class="pure-u-1-5">
            <char if={ letter.is_present(4) } letter={ letter.char } type="present"/>
        </div>
    </div>
    <div class="pure-g char-container">
        <div class="pure-u-1-5" each={ letter in props.letters } if={ letter.is_invalid() }>
            <char letter={ letter.char } type="invalid"/>
        </div>
    </div>
    <script>
        import Char from './char.riot'

        export default {
            components: {
                Char
            }
        }
    </script>
</wordle>

