<finder>
    <div class="pure-g">
        <div class="pure-u-1-3">
            <button onclick={ check } class="pure-button"><i class="fa-solid fa-magnifying-glass"></i> find</button>
        </div>
        <div class="pure-u-1-3">
            <button onclick={ save } class="pure-button"><i class="fa-solid fa-floppy-disk"></i> save</button>
        </div>
        <div class="pure-u-1-3">
            <button onclick={ load } class="pure-button"><i class="fa-solid fa-file-arrow-up"></i> load</button>
        </div>
        <div class="pure-u-1-1 margin-0-5">
            <form class="pure-form form-input" onsubmit={ submit_handler }>
                <input id="word" type="text" value={ state.input } class="pure-input-1">
            </form>
        </div>

        <div class="pure-u-1-1">
            <wordle letters={ state.letters }/>
        </div>
        <div class="pure-u-1-1">
            <div class="pure-g word-container">
                <div each={ word in state.words } if={ word.word } class="pure-u-1-6">
                    <word btn_onclick={ () => toggle_active(word) } word={ word.word }
                          btn_class={ word.active ? 'active' : 'inactive' }/>
                </div>
            </div>
        </div>
    </div>
    <script>
        import User from '../../includes/user/user.riot'
        import {SOLUTIONS, WORDS} from '../../../data/wordle_words'
        import Word from '../../includes/word/word.riot'
        import Char from '../../includes/word/char.riot'
        import Wordle from '../../includes/word/wordle.riot'

        const WORDLE = WORDS.concat(SOLUTIONS).sort(function (a, b) {
            return a.localeCompare(b);
        });

        class Letter {
            constructor(char, position = null, invalid_positions = null) {
                this.char = char;
                this.position = position ? parseInt(position) - 1 : null;
                this.invalid_positions = null;
                if (invalid_positions) {
                    this.invalid_positions = [];
                    for (const inv_pos of invalid_positions) {
                        this.invalid_positions.push(parseInt(inv_pos) - 1);
                    }
                }
            }

            is_valid(position = null) {
                if (position === null) return this.position !== null;
                return this.position !== null && this.position === position;
            }

            is_present(position = null) {
                if (position === null) return this.invalid_positions !== null;
                return this.invalid_positions !== null && this.invalid_positions.includes(position);
            }

            is_invalid() {
                return this.position === null && this.invalid_positions === null;
            }

            valid_word(word, debug = false) {
                if (debug) {
                    console.log('--- valid_word ---');
                    console.log('word', word);
                    console.log('this.char', this.char);
                    console.log('this.position', this.position);
                    console.log('this.invalid_positions', this.invalid_positions);
                }

                // valid letter and position
                if (this.position !== null) {
                    return word.charAt(this.position) === this.char;
                    // valid letter no position
                } else if (this.invalid_positions !== null) {
                    // check if letter is included
                    if (!word.includes(this.char)) return false;
                    // check it is not on invalid positions
                    for (const inv_pos of this.invalid_positions) {
                        if (inv_pos < 5 && word.charAt(inv_pos) === this.char) return false;
                    }
                    return true;
                    // invalid letter
                } else {
                    return !word.includes(this.char);
                }
            }
        }

        function parse_input(input) {
            let letters = [];
            let input_array = input.split(' ');
            for (let arg of input_array) {
                // valid letter and position
                if (arg.charAt(1) === '@') {
                    letters.push(new Letter(arg.charAt(0), arg.charAt(2)));
                    // valid letter no position
                } else if (arg.charAt(1) === '?') {
                    letters.push(new Letter(arg.charAt(0), null, arg.substring(2)));
                    // invalid letter
                } else if (arg.charAt(0) === '!') {
                    for (const letter of arg.substring(1)) {
                        letters.push(new Letter(letter));
                    }
                }
            }
            return letters;
        }

        export default {
            components: {
                User,
                Word,
                Char,
                Wordle
            },
            state: {
                input: '',
                memory: '',
                letters: [],
                words: []
            },
            set_state(key, value) {
                const obj = {};
                obj[key] = value;
                this.update(obj);
            },

            check_letters(word) {
                let valid = false;
                for (const letter of this.state.letters) {
                    valid = letter.valid_word(word);
                    if (!valid) return false;
                }
                return true;
            },

            save() {
                const input = document.getElementById('word').value;
                this.set_state('memory', input);
            },

            load() {
                const input = document.getElementById('word');
                input.value = this.state.memory;
            },
            submit_handler(e) {
                e.preventDefault();
                this.check();
            },
            check() {
                const input = document.getElementById('word').value;
                this.set_state('input', input);
                this.set_state('letters', parse_input(input));
                const valid_words = [];
                //console.log('check', this.check_letters('shall', true));
                //console.log('contains', SOLUTIONS.includes('shall'))
                for (const word of WORDLE) {
                    if (this.check_letters(word)) valid_words.push({word: word, active: true});
                }
                console.log(WORDS.length, SOLUTIONS.length);
                this.set_state('words', valid_words);
                //console.log('valid words', valid_words);
            },

            toggle_active(word) {
                word.active = !word.active;
                this.update();
            },
            toggleUser() {
                this.update({
                    showUser: !this.state.showUser
                })
            }
        }
    </script>
</finder>