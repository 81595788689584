<sidebar>
  <div>
    <div class="row">
      <h1>Sidebar</h1>
    </div>
    <div if={state.showUser} class="row">
      <user name={ state.name }/>
    </div>

    <div class="row">
      <button onclick={toggleUser}>Toggle username</button>
    </div>
  </div>

  <script>
    import User from '../../includes/user/user.riot'

    export default {
      components: {
        User
      },
      state: {
        name: 'John',
        showUser: false
      },
      toggleUser() {
        this.update({
          showUser: !this.state.showUser
        })
      }
    }
  </script>
</sidebar>